<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Table :columns="listColumns" :data="listData" border>
        <template slot-scope="{ row, index }" slot="unit_price">
          <div class="danjia"><span class="fl">¥ </span><i-input class="fl" type="number" style="margin-left: 10px" placeholder="请输入" :value="row.unit_price" maxlength='8' @on-change="changeIpt($event, 'unit_price', index)"></i-input></div>
        </template>
      </Table>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
  import TipsComponent from '@/components/tipsComponent'
  export default {
    name: 'entrustSettlementDetails',
    components: {
      TipsComponent,
    },
    mounted() {
      this.id = this.$route.query.id
      this.queryDetails()
    },
    data() {
      return {
        postStatus: false,
        id: '',
        isLoad: false,
        listColumns: [
          {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
          {
            title: '产品名称',
            key: 'product_name',
            align: 'center',
          },
          {
            title: '规格型号',
            key: 'product_model_name',
            align: 'center',
          },
          {
            title: '条码',
            key: 'bar_code',
            align: 'center',
          },
          {
            title: '批号',
            key: 'batch_number',
            align: 'center',
          },
          {
            title: '序列号',
            key: 'serial_number',
            align: 'center',
          },
          {
            title: '数量',
            key: 'quantity',
            align: 'center',
            width: 90,
          },
          {
            title: '单位',
            key: 'unit',
            align: 'center',
            width: 90,
          },
          {
            title: '销售单价',
            align: 'center',
            width: 160,
            slot: 'unit_price',
          },
          {
            title: '有效期',
            key: 'businessEndDate',
            align: 'center',
            width: 240,
          },
        ],
        listData: [{}],
        total: 1,
        pages: {
          page: 1,
          rows: 10,
        },
        postFrom: {
          id: '',
          price_info: [],
        },
      }
    },
    methods: {
      back() {
        this.$router.go(-1)
      },
      // 输入框改变
      changeIpt(e, name, index) {
        if (e.target.value * 1 < 0) {
          this.$set(this.meterList[index], name, 1)
        } else {
          this.$set(this.meterList[index], name, e.target.value.trim() * 1)
        }
      },
      // 确认提交
      surePost() {
        this.$http.put(this.$api.purchaseCommissioned, this.postFrom, true).then(res => {
          this.$router.go(-1)
        })
      },
      // 点击提交
      save() {
        let arr = []
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].unit_price) {
            this.$Message.warning(`请完善第${i + 1}组产品的销售单价再提交`)
            return
          }
          arr.push({
            purchase_commissioned_line_id: this.listData[i].id,
            unit_price: this.listData[i].unit_price,
          })
        }
        this.postStatus = true
        this.postFrom.id = this.id
        this.postFrom.price_info = arr
      },
      // 查询详情
      queryDetails() {
        this.$http.get(this.$api.purchaseCommissionedDetail, { id: this.id }, true).then(res => {
          this.listData = res.data
          for (let i = 0; i < this.listData.length; i++) {
            this.listData[i].businessEndDate = this.$moment(this.listData[i].business_end_date * 1000).format('YYYY-MM-DD')
            // this.$set(this.listData[i], 'product_total_price', this.meterList[i].product_amount * this.meterList[i].product_unit_price)
          }
        })
      },
    },
  }
</script>

<style scoped lang="less">
  .pagePadding {
    display: flex;
    flex-direction: column;
    .pageTop {
      flex: 1;
    }
    .pageBtm {
      float: right;
      padding-top: 25px;
    }
  }
  .danjia {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
